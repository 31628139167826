/*********************************************\
		Desktop First Method
\*********************************************/
/* Medium Devices, Notebooks */
@media only screen and (max-width : 1261px) {
	#wave1,
	#wave2,
	#wave3,
	#wave4,
	#wave5,
	#wave6 {
		display: none;
	}
	section {
		overflow: hidden;
	}
	.getvaucher {
		padding: 0;
		span {
			display: none;
		}
	}
	.header {
		.mobile-menu-icon {
			display: block;
			transform: translateY(5px);
		}
		.header-menu {
			text-align: center;
			margin-top: 20px;
			display: none;
			li {
				margin: 0 10px;
			}
		}
		.vaucher-btn {
			line-height: 42px;
		}
		.timer-wrap {
			max-width: 275px;
			left: calc(50% - 210px);

			.timer-title {
				font-size: 15px;
				line-height: 20px;
				max-width: 100%;
				text-transform: none;
				display: block;
				text-align: center;
				padding:0;
				br {display: none;}
			}
		}
		.countdownHolder {
			width: 240px;
			margin-top: -23px;
			.textdigit {
				transform: translateY(-12px);
			}
		}
	}

	.sec1 {
		background: #000 url(../img/sec1.png) 50% 0 no-repeat;
		.video {
			display: none;
		}
		.canvas {
			display: none;
		}
		padding-bottom: 96px;
		.date {
			font-size: 19px;
		}
		.title {
			font-size: 64px;
			line-height: 70px;
			letter-spacing: -1.28px;
			max-width: 660px;
		}
		.desc {
			margin-left: 150px;
			margin-bottom: 60px;
		}
		.presale-title {
			font-size: 19px;
		}
		.btn {
			max-width: 331px;
		}
	}

	.sec2 {
		padding-top: 25px;
		padding-bottom: 25px;
		.about-logos {
			background-size: 100%;
			height: 70px;
		}
	}

	.sec3 {
		.item {
			margin-bottom: 40px;
			width: 150px;
			.number {
				font-size: 38px;
				line-height: 45px;
				margin-bottom: 10px;
				&:after {
					font-size: 17px;
				}
			}
			.text {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.sec4 {
		background: url(../img/md/sec4-md.jpg) 50% 526px no-repeat;
		padding-top: 50px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			max-width: 500px;
			margin: 0 auto;
			margin-bottom: 40px;
		}
		.text {
			max-width: 500px;
			margin: 0 auto;
			margin-bottom: 228px;
		}
		.text2 {
			margin-left: 0;
		}
		.text3 {
			transform: translateX(400px);
		}
	}

	.sec5 {
		padding-bottom: 50px;
		background-size: cover;
		.top-text {
			max-width: 400px;
			transform: translateX(400px);
			padding-left: 60px;
			padding-right: 80px;
		}
		.block-title {
			font-size: 38px;
			line-height: 44px;
			max-width: 450px;
			margin-top: -50px;
			margin-bottom: 320px;
			span {
				display: block;
				font-size: 100px;
				line-height: 50px;
				margin-bottom: 30px;
			}
		}
		.slider-trends {
			max-width: 100%;
			height: 274px;
			padding-top: 35px;
			padding-left: 80px;
			padding-right: 80px;
			margin-top: -380px;
		}
		.trend {
			font-size: 28px;
			line-height: 35px;
			padding-top: 80px;
			background-size: 60px;
		}
		.block-desc {
			max-width: 300px;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 0;
			margin-right: 50px;
		}
		.btn {
			max-width: 350px;
		}
	}

	.sec6 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			text-align: center;
			margin-bottom: 10px;
		}
		.video {
			margin: 6px;
		}
	}

	.sec7 {
		padding-top: 50px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
		}
		.part {
			width: 100%;
		}
		.lob18-speakers-block {
			margin-top: 60px;
			margin-bottom: 60px;
			max-width: 425px;
			height: 375px;
			padding-top: 70px;
			img {
				width: 150px;
				margin-bottom:20px;
			}
			.block-text {
				font-size: 28px;
				line-height: 48px;
				&:after {
					margin-top: 20px;
				}
				br {display: none;}
				span {
					display: inline-block;
				}
			}
		}
		.part2 {
			&:before {
				background: url(../img/md/speakers-bg-md.jpg) 50% 0 no-repeat;
				left: - 640px;
			}
		}
	}

	.sec8 {
		padding-top: 50px;
		padding-bottom: 70px;
		.sec-title {
			font-size: 38px;
			line-height: 44px;
			margin-bottom: 30px;
		}
		img {
			width: 100%;
		}
		.slider-reviews {
			max-width: 500px;
			margin: 0 auto;
			.slick-prev {
				left: calc(50% - 300px);
			}
			.slick-next {
				right: calc(50% - 300px);
			}
		}

	}

	.sec9 {
		padding-top: 50px;
		.part {
			width: 100%;
		}
		.sec-title {
			font-size: 48px;
			line-height: 55px;
			max-width: 460px;
			margin: 0 auto;
			margin-bottom: 30px;
		}
		.part1 {
			padding: 0;
			padding-bottom: 50px;
		}
		.text {
			margin: 0 auto;
			margin-bottom: 25px;
		}
		.bottom-text {
			font-size: 24px;
			line-height: 30px;
			margin: 0 auto;
			max-width: 460px;
		}
		.part2 {
			padding-top: 60px;
			padding-bottom: 60px;
			&:before {
				background: url(../img/md/schedule-bg-md.jpg) 50% 0 no-repeat;
				left: - 640px;
			}
		}
		.lob18-schedule-block {
			padding-top: 60px;
			padding-left: 50px;
			height: 430px;
			.block-title {
				font-size: 28px;
				line-height: 34px;
				br {display: none;}
			}
		}
	}

	.sec10 {
		padding-top: 50px;
		padding-bottom: 50px;
		.vaucher-title {
			font-size: 38px;
			line-height: 45px;
			text-align: center;
			margin: 0 auto;
			margin-bottom: 30px;
		}
		.open-sale-block {
			margin: 0 auto;
			margin-bottom: 60px;
		}
	}

	.sec11 {
		padding-top: 100px;
		padding-bottom: 100px;
		background-size: cover;
	}

	.sec12 {
		padding-top: 50px;
		padding-bottom: 70px;
		.sec-title {
			text-align: center;
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 20px;
		}
		.text-block {
			max-width: 620px;
			margin: 0 auto;
			margin-bottom: 10px;
		}
		.slider-video {
			.slick-slide {
				text-align: center;
				div {
					display: inline-block;
					vertical-align: top;
					margin: 0 2px;
				}
			}
			.videolink {
				width: 305px !important;
			}
		}
	}

	.sec13 {
		padding-top: 60px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 38px;
			line-height: 44px;
			text-align: center;
		}
		.company-logos {
			background-size: 100%;
			height: 250px;
		}
	}

	.sec14 {
		padding-top: 50px;
		padding-bottom: 60px;
		background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 30px;
			text-align: center;
		}
		.photos1 {
			background-position: 50% 0;
		}

		.white-block {
			max-width: 600px;
			margin: 0 auto;
			padding: 0;
			padding-top: 60px;
			padding-bottom: 60px;
			.text3,
			.text1,
			.text2 {
				max-width: 460px;
				padding: 0;
				margin: 0 auto;
			}
			.text2 {
				margin-top: 40px;
				margin-bottom: 40px;
			}
			.text3 {
				margin-bottom: 50px;
			}
			.btn {
				margin: 0 auto;
				display: block;
			}
		}
		.photos2 {
			height: 563px;
			background-position: 50% 100%;
			margin-bottom: 50px;
			.video {
				top: 0;
				right: 60px;
			}
		}
		.party-title {
			font-size: 38px;
			line-height: 46px;
			max-width: 600px;
			margin: 0 auto;
			margin-bottom: 30px;
		}
		.party-text {
			max-width: 600px;
			margin: 0 auto;
			margin-bottom: 30px;
		}
		.btn {
			margin-left: 50px;
		}

	}


	.sec15 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 38px;
			line-height: 46px;
			margin-bottom: 20px;
		}
		.item-wrap {
			height: 450px;
		}
	}


	.sec16 {
		padding-top: 190px;
		padding-bottom: 60px;
		height: auto;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			padding: 0;
			margin-bottom: 40px;
		}
		.white-block {
			padding-top: 55px;
			padding-bottom: 55px;
			padding-left:100px;
			margin-bottom: 50px;
		}
		.btn {
			width: 100%;
			max-width: 100%;
			display: block;
		}
		.btn-wrap {
			margin-top: 45px;
			display: block;
			width: 100%;
		}
		#inner-wrap {
			display: none;
		}
	}

	.sec17 {
		padding-top: 50px;
		padding-bottom: 70px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 30px;
		}
		.slick-slide {
			padding: 0 5px;
		}
		// .slide {
		// 	height: 468px;
		// 	background-size: cover;
		// }
		.photo {
			position: relative;
			top: auto;
			bottom: auto;
			left: auto;
			right: auto;
			width: 320px;
			height: 235px;
			// height: auto;
			// height: 225px;
			margin: 0 5px;
		}
	}

	.sec18 {
		padding-top: 100px;
		padding-bottom: 40px;
		.bird {
			width: 262px;
			height: 292px;
			background-size: 262px 292px;
			left: 450px;
			top: 0px;
		}
		.sec-title {
			font-size: 48px;
			max-width: 300px;
			line-height: 56px;
			margin-bottom: 70px;
			margin-left: 100px;
			span:before {
				left: 125px;
			}
		}
		.text {
			max-width: 500px;
			margin: 0 auto;
			b {
				font-weight: 700;
			}
		}
	}

	.sec19 {
		padding-bottom: 60px;
		padding-top: 60px;
		.slider-text {
			background-color: #fff;
		}
		.white-block {
			padding-top: 60px;
			padding-left: 80px;
			padding-right: 100px;
			padding-bottom: 40px;
			margin-bottom: 50px;
			.citate {
				font-size: 28px;
				line-height: 35px;
				margin-bottom: 40px;
			}
			.man-info {
				max-width: 100%;
				.man-photo {
					display: inline-block;
					vertical-align: top;
					width: 60px;
					height: 60px;
					background-size: 60px;
					margin-bottom: 0;
				}
				.man-text {
					display: inline-block;
					vertical-align: top;
				}
			}
		}
		.vaucher-title {
			font-size: 38px;
			line-height: 45px;
			margin-bottom: 25px;
			margin-left: 40px;
			margin-right: 0;
		}
		.open-sale-info {
			max-width: 350px;
			padding-left: 44px;
			margin-bottom: 70px;
			margin-left: 40px;
		}
	}

	.sec20 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 50px;
			text-align: center;
		}
		.container {
			max-width: 100%;
		}
		.map {
			height: 570px;
			margin-bottom: 160px;
		}
		.place-info-block {
			top: 360px;
			right: auto;
			left: calc(50% - 230px);
			box-shadow: 0 32px 80px rgba(67, 0, 105, 0.15);
		}
		.faq {
			max-width: 500px;
			margin: 0 auto;
			.block-title {
				font-size: 38px;
				line-height: 46px;
				text-align: center;
				margin-bottom: 50px;
			}
		}
	}

	.sec21 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 40px;
		}
		.sponsors-block {
			margin-bottom: 80px;
		}
		.sponsors {
			background-size: 100%;
			// height: 50px;
		}
		.partners {
			background-size: 100%;
			// height: 250px;
		}
	}

	.sec22 {
		padding-top: 50px;
		padding-bottom: 60px;
		background-size: cover;
		.sec-title {
			font-size: 38px;
			line-height: 46px;
			margin-bottom: 10px;
			text-align: center;
		}
		.info-block {
			.text2 {
				margin-bottom: 50px;
			}
		}
		.btn {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.sec23 {
		background: url(../img/md/sec23-md.jpg) 50% 0 no-repeat;
		padding-top: 50px;
		.sec-title {
			font-size: 37px;
			line-height: 45px;
			max-width: 300px;
			margin-bottom: 30px;
		}
		.text1 {
			max-width: 260px;
		}
		.text2 {
			margin-bottom: 60px;
		}
		.white-block {
			padding-top: 60px;
			padding-bottom: 60px;
			padding-left: 0;
			background-size: 247px;
			background-position: 50px 0;
			.vaucher-title {
				font-size: 28px;
				line-height: 35px;
				margin-left: 325px;
				br {display: none;}
				max-width: 300px;
				margin-bottom: 53px;
				span {
					display: block;
				}
			}
		}
		.btn {
			margin: 0 auto;
			display: block;
			max-width: 500px;
		}
	}

	.speakers .speakers-title {
		font-size: 40px;
		line-height: 45px;
		text-align: center;
	}


form.standart-form {
	input.name,
	input.email,
	input.phone {
		width: 300px;
		line-height: 62px;
		height: 62px;
	}
	.subm {
		max-width: 300px;
		line-height: 62px;
		margin-top: -62px;
		margin-left: 373px;
		padding-right: 0;
		font-size: 14px;
	}
	.part {
		margin: 0 10px;
	}
}

.modal-white {
	.schedule {
		.tabs {
			li {
				a {
					padding: 0 10px;
				}
			}
		}
	}
}


}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 740px) {


	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}

	.header {
		.phone {
			// display: none;
		}
		.logo {
			display: none;
			img {
				width: 95px;
			}
		}
		.vaucher-btn {
			font-size: 10px;
			line-height: 42px;
			max-width: 100px;
		}
		.header-menu {
			li {
				margin: 0;
				width: 49%;
			}
		}
		.timer-wrap {
			max-width: 165px;
			left: calc(50% - 170px);
			.countdownHolder {
				width: 240px;
				transform: scale(0.7) translateX(-65px);
			}
			.timer-title {
				margin-top: 5px;
				text-align: center;
				font-size: 9px;
				line-height: 15px;
			}
		}
	}

	.sec1 {
		padding-top: 100px;
		padding-bottom: 30px;
		.title {
			font-size: 44px;
			line-height: 45px;
			margin-bottom: 30px;
			span {
					line-height: 45px;
			}
		}
		.desc {
			margin: 0;
			font-size: 16px;
			line-height: 22px;
			br {display: none;}
			margin-bottom: 20px;
			min-height: 88px;
			span {
				padding: 0;
				display: inline-block;
			}
		}
		.presale-title {
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 15px;
			span {
				display: block;
			}
		}
		.btn {
			margin: 0;
			margin-bottom: 20px;
		}
		.btn-video {
			background-position: calc(50% - 100px) 50%;
			padding-left: 30px;
		}
	}

	.sec2 {
		padding-bottom: 20;
		.about-logos {
			height: 55px;
			background: url(../img/sm/about-logos-sm.png) 50% 0 no-repeat;
		}
	}

	.sec3 {
		padding-top: 50px;
		padding-bottom: 20px;
		.w-100 {
			display: none;
		}
		.item {
			margin-bottom: 30px;
			width: 150px;
		}
	}

	.sec4 {
		padding-top: 40px;
		background: url(../img/sm/sec4-sm.png) 50% 525px no-repeat;
		.sec-title {
			font-size: 38px;
			line-height: 45px;
			margin-bottom: 20px;
		}
		.text {
			margin-bottom: 420px;
		}
		.text2 {
			padding: 0;
			background: none;
			padding-bottom: 35px;
			font-weight: 700;
			box-shadow: none;
		}
		.text3 {
			bottom: 265px;
			transform: translateX(150px);
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 30px;
			b {
				font-size: 38px;
				span {font-size: 19px;}
			}
		}
	}

	.sec5 {
		background: #000 url(../img/sec5.jpg) 50% 150px no-repeat;
		.top-text {
			transform: none;
			padding: 0;
			background: none;
			box-shadow: none;
			margin-bottom: 80px;
		}
		.block-title {
			margin-top: 0;
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 290px;
			span {
				display: inline;
				font-size: 28px;
				line-height: 34px;
			}
		}
		.slider-trends {
			padding-left: 30px;
			padding-right: 30px;
			margin-top: -475px;
			.slick-dots {
				bottom: 10px;
			}
			.slick-prev,
			.slick-next {
				bottom: 8px;
			}
		}
		.trend {
			background-size: 55px;
			font-size: 22px;
			line-height: 28px;
			padding-top: 60px;
		}
		.btn {
			margin-top: 25px;
		}
	}


	.sec6 {
		padding-top: 50px;
		.sec-title {
			font-size: 38px;
			line-height: 42px;
		}
		.slider-vedeoreviews-mob {
			.slick-next {
				top: auto;
				bottom: -50px;
			}
			.slick-prev {
				top: auto;
				bottom: -50px;
			}
			.slick-dots {
				bottom: -70px;
			}
		}
	}

	.sec7 {
		padding-top: 60px;
		.sec-title {
			text-align: center;
			font-size: 38px;
			line-height: 42px;
			margin-bottom: 20px;
		}
		.block-white {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 30px;
			padding-bottom: 25px;
			.text {
				br:nth-child(1),
				br:nth-child(2) {display: none !important;}
			}
		}
		.block-black {
			padding-top: 25px;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 25px;
		}
		.lob18-speakers-block {
			padding-top: 50px;
			padding-bottom: 50px;
			height: auto;
			img {
				width: 108px;
				margin-bottom: 25px;
			}
			.block-text {
				font-size: 24px;
				line-height: 33px;
			}
		}
		.part2:before {
			background: url(../img/sm/speakers-bg-sm.jpg) 50% 0 no-repeat;
			background-size: cover;
			width: 750px;
			left: -175px;
		}
	}

	.sec8 {
		padding-top: 50px;
		padding-bottom: 70px;
		.sec-title {
			font-size: 28px;
			line-height: 32px;
			text-align: center;
		}
		.slider-reviews {
			margin: 0 auto;
			.slick-prev {
				left: calc(50% - 180px);
			}
			.slick-next {
				right: calc(50% - 180px);
			}
		}
	}

	.sec9 {
		padding-top: 50px;
		.sec-title {
			font-size: 38px;
			line-height: 42px;
			margin-bottom: 15px;
		}
		.bottom-text {
			font-size: 20px;
			line-height: 25px;
		}
		.lob18-schedule-block {
			padding-top: 50px;
			padding-left: 20px;
			.block-title {
				font-size: 24px;
				line-height: 30px;
			}
		}
	}

	.sec10 {
		background-size: cover;
		.vaucher-title {
			font-size: 28px;
			line-height: 34px;
		}
		.open-sale-block {
			padding-left: 70px;
			padding-right: 0;
		}
		.open-sale-info {
			font-size: 20px;
			line-height: 25px;
		}
	}


	.sec11 {
		padding-top: 60px;
		padding-bottom: 70px;
		background: url(../img/sm/sec11-sm.jpg) 50% 0 no-repeat;
		.sec-title {
			font-size: 28px;
			line-height: 40px;
			margin-right: 0;
			b {
				font-size: 42px;
				&:before {
					left: 70px;
					border-width: 63px 63px 0 0;
				}
			}
		}
		.lob18-link {
			font-size: 20px;
			line-height: 26px;
			padding-right: 70px;
			span {
				right: 0;
				width: 70px;
				height: 70px;
				background-position: 50%;
				background-size: 19px 21px;
				top: 16px;
			}
		}
	}


	.sec12 {
		.sec-title {
			font-size: 38px;
			line-height: 45px;
		}
		.text-block {
			padding: 20px;
			.vaucher-title {
				font-size: 20px;
				line-height: 25px;
			}
		}
		.btn {
			font-size: 16px;
			line-height: 20px;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 50px;
			padding-right: 50px;
		}
		.slider-video {
			.videolink {
				width:320px !important;
			}
		}
	}

	.sec13 {
		.sec-title {
			font-size: 28px;
			line-height: 32px;
		}
	}

	.sec14 {
		padding-bottom: 50px;
		.sec-title {
			font-size: 38px;
			line-height: 44px;
		}
		.photos1 {
			background-size: 100%;
			height: 241px;
		}
		.white-block {
			padding: 10px;
			padding-bottom: 40px;
			padding-top: 20px;
			.text2 {
				font-size: 20px;
				line-height: 25px;
				margin: 20px 0;
			}
			.text3 {
				margin-bottom: 20px;
			}
		}
		.party-title {
			font-size: 28px;
			line-height: 32px;
			margin-top: 0px;
			margin-bottom: 20px;
		}
		.btn {
			margin: 0;
			font-size: 16px;
		}
		.photos2 {
			background-size: 100%;
			background-position: 50% 100%;
			height: 300px;
			margin-bottom: 40px;
			.video {
				right: 0;
				width: 160px;
				height: 110px;
			}
		}
	}

	.sec15 {
		padding-top: 50px;
		padding-bottom: 0;
		.sec-title {
			font-size: 28px;
			line-height: 32px;
			text-align: center;
		}
		.item-wrap {
			background-size: 100%;
			background-position: 50% 0;
			margin-left: -140px;
			margin-right: -140px;
			height: 700px;
			padding-top: 260px;
			background-size: 100%;
			height: auto;
		}
		.item {
			width: 100%;
			height: 350px;
			.item-text {
				&:before {
					font-size: 66px;
					line-height: 70px;
					margin-bottom: 20px;
				}
			}
		}
	}

	.sec16 {
		padding-top: 70px;
		.sec-title {
			font-size: 38px;
			line-height: 45px;
			margin-bottom: 40px;
		}
		.white-block {
			padding: 20px;
			margin-bottom: 45px;
			.text-big {
				font-size: 20px;
				line-height: 25px;
				margin: 10px 0;
			}
		}
		.bottom-block {
			.block-title{
				font-size: 20px;
				line-height: 25px;
				max-width: 250px;
				margin-bottom: 15px;
			}
		}
		.btn {
			font-size: 16px;
			line-height: 20px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.sec17 {
		.sec-title {
			font-size: 38px;
			line-height: 42px;
		}
	}

	.sec18 {
		padding-top: 50px;
		.bird {
			top: 160px;
			left: calc(50% - 150px);
		}
		.sec-title {
			margin-left: 0;
			font-size: 38px;
			line-height: 45px;
			margin-bottom: 310px;
			span {
				&:before {
					left: 100px;
					border-width: 69px 69px 0 0;
				}
			}
		}
	}

	.sec19 {
		padding-top: 30px;
		padding-bottom: 50px;
		.white-block {
			padding: 60px;
			padding-top: 50px;
			padding-bottom: 50px;
			padding-left: 10px;
			padding-right: 10px;
			.citate{
				font-size: 20px;
				line-height: 25px;
				&.big {
					font-size: 18px;
				}
			}

		}
		.vaucher-title {
			margin: 0;
			font-size: 28px;
			line-height: 35px;
			margin-bottom: 10px;
		}
		.open-sale-info {
			margin-left: 0;
			margin-bottom: 50px;
		}
	}

	.sec20 {
		padding-top: 40px;
		padding-bottom: 0;
		.sec-title {
			font-size: 38px;
			line-height: 45px;
			margin-bottom: 30px;
		}
		.place-info-block{
			width: 390px;
			padding-left: 44px;
			left: calc(50% - 195px);
			padding-top: 226px;
			background: #fff url(../img/sm/place-bg-sm.jpg) 50% 0 no-repeat;
			height: auto;
			padding-bottom: 45px;
		}
		.faq {
			margin-top: 350px;
			max-width: 320px;
			.block-title {
				font-size: 28px;
				line-height: 32px;
				letter-spacing: -0.56px;
			}
			.question {
				margin-bottom: 40px;
			}
			.question-text {
				font-size: 20px;
				line-height: 25px;

			}
		}
	}

	.sec21 {
		.sec-title {
			font-size: 38px;
			line-height: 42px;

			letter-spacing: -0.76px;
		}
	}

	.sec22 {
		padding-bottom: 30px;
		.sec-title {
			font-size: 28px;
			line-height: 32px;
			letter-spacing: -0.56px;
			text-align: left;
			span {
				display: block;
				font-size: 16px;
				line-height: 20px;
				margin-top: 10px;
				margin-bottom: 50px;
			}
		}
		.info-block {
			.text1 {
				margin-bottom: 40px;
			}
			.text2 {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}

	.sec23 {
		background: #090909 url(../img/sm/sec23-sm.jpg) 50% 0 no-repeat;
		.sec-title {
			font-size: 28px;
			line-height: 35px;
			letter-spacing: -0.56px;
			max-width: 250px;
		}
		.text1 {
			max-width: 200px;
		}
		.white-block {
			padding-top: 50px;
			padding-bottom: 50px;
			margin-left: -50px;
			margin-right: -50px;
			padding-left: 50px;
			padding-right: 50px;
			background-size: 180px;
			background-position: -20px 0;
			.vaucher-title {
				font-size: 24px;
				line-height: 30px;
				margin-left: 0;
				margin-left: 80px;
				max-width: 320px;
			}
		}
	}




.getvaucher {
	padding: 0;
	span {display: none;}
}


form.standart-form {
	.subm {
		margin: 0 auto;
	}
	input.name,
	input.email,
	input.phone {
		line-height: 56px;
		height: 56px;
	}
}

.modal-white {
	.close-button {
		position: fixed;
		top: 0;
		right: 0;
		width: 60px;
		height: 60px;
		padding: 0;
	}
}

.modal-white {
	.schedule {
		.schedule-title {
			font-size: 18px;
			line-height: 20px;
			br {display: none;}
			margin-bottom: 20px;
		}
		.tabs {
			li {
				a {
					padding: 0 5px;
					span {
						font-size: 17px;
						line-height: 22px;
					}
				}
			}
		}
	}
	.day {
		.day-title {
			font-size: 40px;
			line-height: 45px;
		}
	}
	.day-item {
		.item-content {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 20px;
			padding-bottom: 30px;
		}
		.item-number {
			padding-top: 10px;
		}
	}
	.coffe-break {
		.item-content {
			.coffee {
				margin-right: 50px;
			}
		}
	}
	.dinner {
		.item-content {
			.dinner-text {
				margin-right: 20px;
			}
		}
	}
}

}


/* Custom, iPhone Retina */
@media only screen and (max-width : 340px) {
		.container {
		padding: 0;
		overflow: hidden;
		width: 320px;}
}


/*********************************************\
		Mobile First Method
\*********************************************/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {

}


/* Small Devices, Tablets */
@media only screen and (min-width : 720px) {

}


/* Medium Devices, Desktops */
@media only screen and (min-width : 960px) {

}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1130px) {

}